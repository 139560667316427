import { useToast } from '@component-utils/toasts'
import Utils from '../utils'

const CONTENT_TYPE_JSON = 'application/json'

const STATUS_OK = 200

type RequestMethod = 'get' | 'post'

const _avv_download_manager = new (class AvvDownloadManager {
  constructor() {
    window.addEventListener('DOMContentLoaded', () => {
      this.#register(document)
    })

    window.addEventListener('mainContentChange', (event: Event) => {
      const detail = event.detail as undefined | { element: HTMLElement }
      if (detail?.element) {
        this.#register(detail.element)
      }
    })
  }

  async download(
    url: string,
    params: unknown = {},
    method: RequestMethod = 'post'
  ) {
    const toast = useToast({
      message: this.#localize('pending'),
      icon: 'autorenew',
      type: 'loading'
    })

    const { status, headers, data } = await this.#axios(url, params, method)

    if (status === STATUS_OK) {
      const disposition = headers['content-disposition']
      const name = this.#getFilenameFromContentDisposition(
        disposition as string
      )

      this.#download(data, name)

      toast.update({
        message: this.#localize('status_200'),
        icon: 'download_done',
        type: 'success'
      })

      return true
    } else if (data.type === CONTENT_TYPE_JSON) {
      const { error } = JSON.parse(await data.text())

      toast.update({
        message: error,
        icon: 'exclamation',
        type: 'warning'
      })
    } else {
      try {
        toast.update({
          message: (JSON.parse(await data.text()) as { error: string }).error,
          icon: 'error',
          type: 'error'
        })
      } catch {
        toast.update({
          message: this.#localize('status_500'),
          icon: 'error',
          type: 'error'
        })
      }
    }

    return false
  }

  #localize(key: string, params?: Record<string, unknown>) {
    return window.localizeText(`avv_download.${key}`, params)
  }

  #getFilenameFromContentDisposition(disposition: string) {
    const segments = disposition.split(';').map((segment) => segment.trim())

    let segment = null
    if ((segment = segments.find((s) => /^filename\*=UTF-8''/.test(s)))) {
      // UTF-8 filename, must be decoded from % encoding
      return decodeURIComponent(segment.slice(17))
    } else if ((segment = segments.find((s) => /^filename="[^"]+"$/.test(s)))) {
      // ASCII filename
      return segment.slice(10, -1)
    } else {
      // Return current date if no filename is supplied
      return String(Date.now())
    }
  }

  #register(container: Document | HTMLElement) {
    for (const element of Array.from(
      container.querySelectorAll<HTMLElement>('[data-download]')
    )) {
      const [method, url] = element.dataset.download?.split(':', 2) ?? []

      element.removeAttribute('data-download')

      if (method && url) {
        element.addEventListener('click', () => {
          void this.download(url, {}, method as RequestMethod)
        })
      }
    }
  }

  #axios(url: string, data: unknown, method: RequestMethod) {
    return Utils.axios<Blob>({
      url,
      data,
      method,
      responseType: 'blob',
      validateStatus: () => true
    })
  }

  #download(blob: Blob, name: string) {
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = name
    link.click()
    link.remove()

    URL.revokeObjectURL(url)
  }
})()

const _avv_download = (
  url: string,
  params: unknown = {},
  method: RequestMethod = 'post'
): Promise<boolean> => {
  return window.avv_download_manager.download(url, params, method)
}

window.avv_download_manager = _avv_download_manager
window.avv_download = _avv_download

declare global {
  interface Window {
    avv_download_manager: typeof _avv_download_manager
    avv_download: typeof _avv_download
  }

  const avv_download_manager: typeof _avv_download_manager
  const avv_download: typeof _avv_download
}

export default {}
